@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.material-icons.light.icon-xs {
  font-size: 24px;
  color: white;
}

.material-icons.dark.icon-xs {
  font-size: 24px;
  color: black;
}

.material-icons.light.icon-sm {
  font-size: 45px;
  color: white;
}

.material-icons.dark.icon-sm {
  font-size: 45px;
  color: black;
}

.material-icons.light.icon-md {
  font-size: 65px;
  color: white;
}

.material-icons.dark.icon-md {
  font-size: 65px;
  color: black;
}

.material-icons.light.icon-lg {
  font-size: 100px;
  color: white;
}

.material-icons.dark.icon-lg {
  font-size: 100px;
  color: black;
}

.material-icons.light.icon-xl {
  font-size: 150px;
  color: white;
}

.material-icons.dark.icon-xl {
  font-size: 150px;
  color: black;
}

.App {
  height: 100%;
  width: 100%;
}

html,
body {
  margin: 0;
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
}
/*# sourceMappingURL=App.css.map */
.chat-room-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-room-container .sub-container {
  width: 40%;
  height: 60%;
  background-color: whitesmoke;
  box-shadow: 0 0 10px;
  border: 0;
  border-radius: 20px;
}

.chat-room-container .sub-container .messages {
  width: 100%;
  height: 80%;
  overflow-y: scroll;
  padding: 20px;
  -ms-overflow-style: none;
}

.chat-room-container .sub-container .messages::-webkit-scrollbar {
  display: none;
}

.chat-room-container .sub-container .messages p {
  max-width: 70%;
  padding: 10px 15px 10px 15px;
  border-radius: 15px;
}

.chat-room-container .sub-container .messages .me {
  float: right;
  clear: both;
  text-align: right;
  background-color: #6666ff;
  color: white;
}

.chat-room-container .sub-container .messages .other {
  clear: both;
  float: left;
  background-color: #cccccc;
}

.chat-room-container .sub-container .input-layout {
  width: 100%;
  height: 20%;
  background-color: #6666ff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border: 0;
}

.chat-room-container .sub-container .input-layout input[type="text"] {
  padding: 5px 10px 5px 10px;
  width: 40%;
  transition: all 0.3s;
  border-radius: 15px;
  border: 0;
}

.chat-room-container .sub-container .input-layout input[type="text"]:focus {
  width: 80%;
}

.chat-room-container .sub-container .input-layout input[type="submit"] {
  padding: 5px;
  width: 10%;
  margin-left: 10px;
  border: 0;
  border-radius: 15px;
  font-size: 35px;
  color: white;
  background-color: transparent;
}
/*# sourceMappingURL=ChatRoom.css.map */
.sidebar {
  height: 100%;
  width: 30%;
  box-shadow: 2px 0px 25px;
  background-color: #ffffff;
  transition: 0.3s;
  position: fixed;
}

.sidebar .menu {
  float: left;
  width: 20%;
  height: 100%;
  background-color: #673ab7;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.sidebar .menu a {
  color: black;
}

.sidebar .menu a i {
  display: block;
  color: #ffffff;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 45px;
}

@media only screen and (max-width: 598px) and (min-width: 50px) {
  .sidebar .menu a i {
    font-size: 30px;
  }
}

@media only screen and (max-width: 959px) and (min-width: 600px) {
  .sidebar .menu a i {
    font-size: 30px;
  }
}

.sidebar .menu a:hover {
  text-decoration: none;
}

@media only screen and (max-width: 598px) and (min-width: 50px) {
  .sidebar .menu {
    width: 15%;
  }
}

@media only screen and (max-width: 959px) and (min-width: 600px) {
  .sidebar .menu {
    width: 15%;
  }
}

.sidebar .friend-list {
  width: 80%;
  height: 100%;
  float: left;
  padding: 10px;
  background-color: #e8e1f5;
  overflow-y: scroll;
  -ms-overflow-style: none;
}

.sidebar .friend-list::-webkit-scrollbar {
  display: none;
}

.sidebar .friend-list .friend {
  width: 100%;
  padding: 15px;
  border: 0;
  border-bottom: 1px solid black;
  background-color: white;
}

.sidebar .friend-list .friend img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 2px;
}

.sidebar .friend-list .friend img.online {
  border: 2px solid #00b300;
}

@media only screen and (max-width: 598px) and (min-width: 50px) {
  .sidebar .friend-list .friend img.online {
    border: 1px solid #00b300;
  }
}

@media only screen and (max-width: 959px) and (min-width: 600px) {
  .sidebar .friend-list .friend img.online {
    border: 1px solid #00b300;
  }
}

.sidebar .friend-list .friend img.offline {
  border: 1px solid gray;
}

.sidebar .friend-list .friend a {
  text-decoration: none;
  color: #212121;
}

.sidebar .friend-list .friend a span {
  text-overflow: ellipsis;
  font-size: 0.8em;
  margin-left: 10px;
  font-weight: 600;
  width: 85%;
}

.sidebar .friend-list .friend p {
  font-size: 0.7em;
  margin: 0;
  margin-left: 60px;
  width: 70%;
}

@media only screen and (max-width: 598px) and (min-width: 50px) {
  .sidebar .friend-list .friend p {
    font-size: 0.6em;
  }
}

@media only screen and (max-width: 959px) and (min-width: 600px) {
  .sidebar .friend-list .friend p {
    font-size: 0.6em;
  }
}

.sidebar .friend-list .friend#active {
  background-color: #673ab7;
  color: #ffffff;
  border-bottom: 0;
}

.sidebar .friend-list .friend#active a {
  color: #ffffff;
}

@media only screen and (max-width: 598px) and (min-width: 50px) {
  .sidebar .friend-list {
    width: 85%;
  }
}

@media only screen and (max-width: 959px) and (min-width: 600px) {
  .sidebar .friend-list {
    width: 85%;
  }
}

.sidebar .slideBtn {
  float: left;
  position: fixed;
  left: 9px;
  width: 10%;
  height: 10%;
  color: #ffffff;
  display: none;
  top: 2vh;
  -ms-grid-row-align: center;
  align-self: center;
  font-size: 30px;
}

@media only screen and (max-width: 598px) and (min-width: 50px) {
  .sidebar .slideBtn {
    display: block;
  }
}

@media only screen and (max-width: 959px) and (min-width: 600px) {
  .sidebar .slideBtn {
    display: block;
  }
}

@media only screen and (max-width: 598px) and (min-width: 50px) {
  .sidebar {
    box-shadow: 0px 0px 0px;
    margin-left: -30%;
  }
}

@media only screen and (max-width: 959px) and (min-width: 600px) {
  .sidebar {
    box-shadow: 0px 0px 0px;
    margin-left: -30%;
  }
}

@media only screen and (max-width: 1279px) and (min-width: 960px) {
  .sidebar {
    width: 40%;
  }
}

#open {
  margin-left: 0;
  width: 85%;
}

@media only screen and (max-width: 1279px) and (min-width: 960px) {
  #open {
    width: 40%;
  }
}

@media only screen and (max-width: 1919px) and (min-width: 1280px) {
  #open {
    width: 30%;
  }
}

@media only screen and (max-width: 5000px) and (min-width: 1920px) {
  #open {
    width: 30%;
  }
}

#close {
  margin-left: -85%;
  width: 85%;
}

@media only screen and (max-width: 1919px) and (min-width: 1280px) {
  #close {
    width: 30%;
    margin-left: 0;
  }
}

@media only screen and (max-width: 5000px) and (min-width: 1920px) {
  #close {
    width: 30%;
    margin-left: 0;
  }
}

@media only screen and (max-width: 1279px) and (min-width: 960px) {
  #close {
    width: 40%;
    margin-left: 0;
  }
}
/*# sourceMappingURL=index.css.map */
.message-view {
  width: 70%;
  height: 100%;
  margin-left: 30%;
  display: flex;
  justify-content: center;
}

.message-view .sub-message-view {
  width: 60%;
  height: 100%;
  box-shadow: 0px 0px 10px;
}

.message-view .sub-message-view .header {
  width: 100%;
  height: 10%;
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  justify-content: flex-end;
  align-items: center;
  background-color: #673ab7;
}

.message-view .sub-message-view .header img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  padding: 1px;
  margin-left: 5px;
}

.message-view .sub-message-view .header img.online {
  border: 1px solid #00e600;
}

.message-view .sub-message-view .header img.offline {
  border: 1px solid gray;
}

.message-view .sub-message-view .header span {
  margin: 0px;
  font-size: 0.8em;
  color: #ffffff;
}

.message-view .sub-message-view .message-main {
  height: 80%;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.message-view .sub-message-view .message-main .message-list {
  width: 100%;
  height: 100%;
  padding: 15px;
  background-color: white;
  overflow-y: scroll;
  -ms-overflow-style: none;
}

.message-view .sub-message-view .message-main .message-list::-webkit-scrollbar {
  display: none;
}

.message-view .sub-message-view .message-main .message-list .message {
  max-width: 70%;
  width: auto;
  background-color: silver;
  margin-bottom: 20px;
  color: #212121;
  padding: 15px;
  border-radius: 15px;
}

.message-view .sub-message-view .message-main .message-list .message p {
  font-size: 0.85em;
  white-space: wrap;
  margin: 0;
}

.message-view .sub-message-view .message-main .message-list .message span {
  white-space: wrap;
  font-size: 0.75em;
  display: block import;
}

.message-view .sub-message-view .message-main .message-list .message#me {
  float: right;
  clear: both;
  background-color: #6666ff;
  color: white;
}

.message-view .sub-message-view .message-main .message-list .message#other {
  float: left;
  clear: both;
}

.message-view .sub-message-view .input-box {
  width: 100%;
  height: 10%;
  display: flex;
  background-color: #673ab7;
  justify-content: center;
  align-items: center;
  box-shadow: -2px 0px 10px;
}

.message-view .sub-message-view .input-box i {
  font-size: 30px;
  margin-right: 10px;
  color: #ffffff;
}

.message-view .sub-message-view .input-box input[type="text"] {
  width: 40%;
  padding: 10px 10px 10px 10px;
  border-radius: 15px;
  border: 0;
  font-size: 0.7em;
  transition: 0.3s;
}

.message-view .sub-message-view .input-box input[type="text"]:focus {
  width: 65%;
}

.message-view .sub-message-view .input-box input[type="submit"] {
  font-size: 40px;
  margin-left: 10px;
  border: 0;
  color: #ffffff;
  background-color: transparent;
}

@media only screen and (max-width: 598px) and (min-width: 50px) {
  .message-view .sub-message-view .input-box input[type="submit"] {
    font-size: 30px;
  }
}

@media only screen and (max-width: 959px) and (min-width: 600px) {
  .message-view .sub-message-view .input-box input[type="submit"] {
    font-size: 30px;
  }
}

@media only screen and (max-width: 598px) and (min-width: 50px) {
  .message-view .sub-message-view {
    width: 100%;
    box-shadow: 0px;
  }
}

@media only screen and (max-width: 959px) and (min-width: 600px) {
  .message-view .sub-message-view {
    width: 100%;
    box-shadow: 0px;
  }
}

@media only screen and (max-width: 598px) and (min-width: 50px) {
  .message-view {
    width: 100%;
    margin: 0;
  }
}

@media only screen and (max-width: 959px) and (min-width: 600px) {
  .message-view {
    width: 100%;
    margin: 0;
  }
}
/*# sourceMappingURL=index.css.map */
